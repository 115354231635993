import React from 'react';
import { graphql } from 'gatsby';

import { getLocalePage } from '../../hooks/useLocale';
import PageContext from '../../hooks/pageContextProvider';
import { ButtonLink } from '../../components/Atoms/Button/Button';
import { SiteFooter } from '../../components/SiteFooter/SiteFooter';
import Wrapper from '../../components/Wrapper/Wrapper';
import SiteHeader from '../../components/SiteHeader/SiteHeader';
import {
  SitemapHero,
  StyledSiteGrid,
  StyledWrapper,
  StyledSection,
  StyledH1,
  StyledList,
  ListContainer,
  StyledH2,
} from '../../style/sitemapStyles';

const SitemapPageDe = ({ data }) => {
  const headerData = data?.allContentfulComponentSiteHeader.nodes[0];
  const footerData = data?.allContentfulComponentSiteFooter.nodes[0];
  const stickyBanner = data?.allContentfulComponentStickyBanner.nodes;
  const sitemapData = data?.contentfulWebsite.htmlSitemap;
  const heroText = sitemapData[0].content.secondaryText;
  const locale = 'de-DE';

  return (
    <PageContext.Provider value={{ locale }}>
      <SiteHeader content={headerData} stickyBanner={stickyBanner} />
      <SitemapHero>
        <Wrapper>
          <StyledSiteGrid>
            <StyledH1>{heroText}</StyledH1>
          </StyledSiteGrid>
        </Wrapper>
      </SitemapHero>
      <StyledSection>
        <StyledWrapper>
          {sitemapData.map((data) => (
            <ListContainer>
              <StyledH2>{data.content.primaryText}</StyledH2>
              <StyledList>
                {data.contentItems.map((item) => (
                  <li key={item.id}>
                    {item.link.map((link) => (
                      <ButtonLink
                        variant="tertiary"
                        size="small"
                        to={getLocalePage(link, locale)}
                      >
                        {link.text}
                      </ButtonLink>
                    ))}
                  </li>
                ))}
              </StyledList>
            </ListContainer>
          ))}
        </StyledWrapper>
      </StyledSection>
      <SiteFooter {...footerData} />
    </PageContext.Provider>
  );
};

export default SitemapPageDe;

export const query = graphql`
  query SitemapQuery {
    allContentfulComponentStickyBanner(
      filter: { node_locale: { eq: "de-DE" } }
    ) {
      nodes {
        ...StickyBannerFragment
      }
    }
    allContentfulComponentSiteHeader(filter: { node_locale: { eq: "de-DE" } }) {
      nodes {
        ...SiteHeaderFragment
      }
    }
    allContentfulComponentSiteFooter(filter: { node_locale: { eq: "de-DE" } }) {
      nodes {
        ...SiteFooterFragment
      }
    }
    allContentfulContentMicrocopy(filter: { node_locale: { eq: "de-DE" } }) {
      nodes {
        ...MicroCopyFragment
      }
    }
    contentfulWebsite(node_locale: { eq: "de-DE" }) {
      htmlSitemap {
        content {
          secondaryText
          bodyText {
            raw
          }
          primaryText
        }
        contentItems {
          primaryText
          secondaryText
          id
          link {
            ...LinkFragment
          }
        }
      }
    }
  }
`;
